<div id="third-page">
  <img id="third-page-image" src="/assets/img/growth.jpeg">
  <div id="third-page-text">
    <p id="third-page-title">What we do</p>
    <p class="sub-title">FUND</p>
    <p class="sub-description">
      Our investment strategy is focused on long-term sustainability. We work to
      create a financially well-being future for our customers. Our portfolio
      managers deeply look into business opportunities to evaluate securities
      and risks in order to match our investment criterion.
    </p>
    <p class="sub-title">START-UPs</p>
    <p class="sub-description">
      We look for innovative ideas and highly motivated startup companies to
      work with. When your company matches our criterion we will offer financial
      and advising support to your business. Your growth is in our interest.
    </p>
  </div>
</div>
