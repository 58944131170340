import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './sections/header/header.component';
import { FirstPageComponent } from './sections/first-page/first-page.component';
import { SecondPageComponent } from './sections/second-page/second-page.component';
import { ThirdPageComponent } from './sections/third-page/third-page.component';
import { FourthPageComponent } from './sections/fourth-page/fourth-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FirstPageComponent,
    SecondPageComponent,
    ThirdPageComponent,
    FourthPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
