<div id="first-page">
  <app-header></app-header>
  <div id="background">
    <img id="skyscraper" src="/assets/img/skyscraper.jpeg" />
    <div id="img-opacity-overlay"></div>
  </div>
  <div id="landing-text-content">
    <p id="small-title">Sustainable growth</p>
    <p id="big-title">With your future in our interest</p>
  </div>
  <svg
  id="pattern"
    xmlns="http://www.w3.org/2000/svg"
    width="499.752"
    height="484.999"
    viewBox="0 0 499.752 484.999"
  >
    <path
      id="path"
      data-name="Subtraction 1"
      d="M-4494.979,1071h-.931V1014.38a443.462,443.462,0,0,0,129.216-19.3,443.868,443.868,0,0,0,115.778-54.632,443.866,443.866,0,0,0,95.4-85.368,443.46,443.46,0,0,0,68.087-111.5c5.313-12.558,10.1-25.507,14.224-38.487,4.09-12.867,7.63-26.076,10.522-39.26A444.16,444.16,0,0,0-4052.652,586h56.4a498.632,498.632,0,0,1-24.307,139.7,501.482,501.482,0,0,1-61.682,126.046,499.333,499.333,0,0,1-94.945,104.592,499.815,499.815,0,0,1-124.1,75.336c-15.231,6.45-30.964,12.2-46.761,17.093-15.76,4.88-31.949,9.019-48.117,12.3s-32.688,5.784-49.1,7.436C-4461.714,1070.16-4478.442,1071-4494.979,1071ZM-4496,807.072h0V780.33h.652a208.476,208.476,0,0,0,40.887-4.057,208.508,208.508,0,0,0,38.936-11.867,208.513,208.513,0,0,0,35.883-19.222,208.507,208.507,0,0,0,31.728-26.121,210.14,210.14,0,0,0,24.337-29.212,209.53,209.53,0,0,0,18.361-32.5,210.106,210.106,0,0,0,12.208-34.911A210.348,210.348,0,0,0-4287.126,586h26.767a236.31,236.31,0,0,1-7.212,44.926,235.016,235.016,0,0,1-15.253,41.672,236.614,236.614,0,0,1-22.452,37.575,238.029,238.029,0,0,1-28.808,32.634,237.663,237.663,0,0,1-34.319,26.851,235.975,235.975,0,0,1-38.987,20.223,234.951,234.951,0,0,1-42.813,12.752A237.986,237.986,0,0,1-4496,807.072Zm3.577-97.855c-1.153,0-2.328-.015-3.489-.044V655.708a84.456,84.456,0,0,0,29.589-5.341,84.451,84.451,0,0,0,25.032-14.678,84.889,84.889,0,0,0,18.555-22.1A84.017,84.017,0,0,0-4412.575,586h57.5a138.366,138.366,0,0,1-6.133,28.308,138.437,138.437,0,0,1-12.151,26.825,138.174,138.174,0,0,1-22.225,28.462,138.255,138.255,0,0,1-28.312,21.423,138.325,138.325,0,0,1-32.809,13.5A138.136,138.136,0,0,1-4492.422,709.217Z"
      transform="translate(4496 -586)"
      fill="#fafafa"
    />
  </svg>
</div>
