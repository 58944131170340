<div id="fourth-page" class="contact">
  <div id="fourth-page-text">
  <img id="logo" src="/assets/img/logo-white.png">

<div  class="contact-end-point">
  <table>
    <tr>
      <td class="contact-end-point-title">Regist. nr.</td>
      <td class="contact-end-point-item">2007589.01</td>
    </tr>
    <tr>
      <td class="contact-end-point-title">Email</td>
      <td class="contact-end-point-item">info@sailyinvestment.ae</td>
    </tr>
    <tr>
      <td class="contact-end-point-title">Telephone</td>
      <td class="contact-end-point-item">+971 4252 0335</td>
    </tr>
    <tr>
      <td class="contact-end-point-title" style="vertical-align: top;">Office</td>
      <td class="contact-end-point-item"> <address>SailyInvestment LLC
        <br />
        3507, 35th Floor,<br/>
        Burlington Tower,<br/>
        Al Abraj Street, Business Bay,<br/>
        Dubai - UAE</address></td>
    </tr>
  </table>
</div>

</div>
<img id="fourth-page-image" src="/assets/img/contact.jpg" />
</div>