import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    var button = document.getElementById('aboutUs');
    button.addEventListener('click', () => {
      var section = document.getElementsByClassName('aboutUs');
      section[0].scrollIntoView({behavior: "smooth"});
    });


    var button2 = document.getElementById('contact');
    button2.addEventListener('click', () => {
      var section = document.getElementsByClassName('contact');
      section[0].scrollIntoView({behavior: "smooth"});
    });
  }

}
